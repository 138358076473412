type TError = Error & {
    response: object;
};

const fetchDashboardJSON = (
	{ baseUrl, sharedDashboardUuid, password, signal }: {
		baseUrl: string,
		sharedDashboardUuid?: string,
		password: string,
		signal?: AbortSignal,
	}
) => {
	if (!sharedDashboardUuid) {
		return Promise.reject(new Error('Shared dashboard UUID is required'));
	}

	if (!baseUrl) {
		return Promise.reject(new Error('Base URL is required'));
	}

	const fullUrl = `${baseUrl}/dashboard/${sharedDashboardUuid}`;
	const auth = `basic ${btoa(`:${password}`)}`;

	return fetch(fullUrl, {
		method: 'GET',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			...(password !== ''
				? {
					Authorization: auth,
				}
				: {}),
		},
		signal,
	})
		.then(checkStatus)
		.then((response) => response.json());
};

const checkStatus = (response: Response): Response => {
	if (response && response.status >= 200 && response.status < 300) {
		return response;
	}

	const error = new Error(response.statusText) as TError;
	error.response = response;
	throw error;
};

export { fetchDashboardJSON };
