import React, { ReactElement, SetStateAction, useCallback, useEffect, useRef, useState } from 'react';
import { Route, Routes, useParams } from 'react-router-dom';
import { Loader } from './components/loader';
import { fetchDashboardJSON } from './helpers/requestHelper';
import { Dashboard } from './routes/dashboard';
import { Error } from './routes/error';
import { Expired } from './routes/expired';
import { Password } from './routes/password';
import { Passwordless } from './routes/passwordless';
import { track, eventNames } from'./helpers/userTracking';
import './App.css';

const FIVE_MINUTES = 5 * 60 * 1000;
const REFETCH_CANCEL_BUFFER_TIME = 20 * 60 * 1000;

const trackDashboardIncorrectPassword = () => track(eventNames.DASHBOARD_INCORRECT_PASSWORD);
const trackDashboardOpened = (passwordSet: ('Y' | 'N')) => track(eventNames.DASHBOARD_OPENED, { password_set: passwordSet });

const SharedDashboardContainer = (props: { baseUrl: string }) => {
	const { baseUrl } = props;
	const { sharedDashboardUuid } = useParams<{ sharedDashboardUuid: string; }>();

	const [dashboardChecked, setDashboardChecked] = useState(false);
	const [dashboardValid, setDashboardValid] = useState(false);
	const [passwordNeeded, setPasswordNeeded] = useState(false);
	const [password, setPassword] = useState('');
	const [expired, setExpired] = useState(false);
	const [showDashboard, setShowDashboard] = useState(false);
	const [dashboardJSON, setDashboardJSON] = useState(null);

	const [timeoutId, setTimeoutId] = useState<null | NodeJS.Timeout>(null);
	const [abortController, setAbortController] = useState<AbortController | null>(null);
	const windowBlurTimestampRef = useRef<null | number>(null);

	useEffect(() => {
		fetchDashboard();

		return () => {
			if (timeoutId) {
				clearTimeout(timeoutId);
			}

			abortController?.abort();
		};
	}, [password]);

	const fetchDashboard = useCallback(() => {
		const newAbortControllerInstance = new AbortController();

		setAbortController(newAbortControllerInstance);

		fetchDashboardJSON({
			baseUrl,
			sharedDashboardUuid,
			password,
			signal: newAbortControllerInstance?.signal,
		})
			.then((dashboardJSON) => {
				setDashboardJSON(dashboardJSON as SetStateAction<null>);
				setDashboardValid(true);

				const windowBlurTimestamp = windowBlurTimestampRef.current;

				// If window isn't blurred or has been blurred for less than REFETCH_CANCEL_BUFFER_TIME, refetch the dashboard
				if (windowBlurTimestamp === null || Date.now() - windowBlurTimestamp < REFETCH_CANCEL_BUFFER_TIME) {
					fetchDashboardPoll();
				} else {
					setTimeoutId(null);
				}

				if (password !== '' && passwordNeeded) {
					trackDashboardOpened('Y');
				}
			})
			.catch((error) => {
				console.error(error);

				const errorStatus = error?.response?.status || -1;

				if (errorStatus === 401) {
					if (passwordNeeded) {
						trackDashboardIncorrectPassword();
					}

					// Incorrect password
					setDashboardValid(true);
					setPasswordNeeded(true);
				} else if (errorStatus === 404) {
					// Asset expired
					setDashboardValid(true);
					setExpired(true);
				} else {
					console.error('Unrecognised error', error);
					setDashboardValid(false);
				}
			})
			.finally(() => {
				// We have successfully requested information on the dashboard
				setDashboardChecked(true);
			});
	}, [baseUrl, password, passwordNeeded, sharedDashboardUuid]);

	const fetchDashboardPoll = useCallback(() => {
		const timeoutId = setTimeout(fetchDashboard, FIVE_MINUTES);

		setTimeoutId(timeoutId);
	}, [fetchDashboard]);

	const windowFocused = useCallback(() => {
		if (showDashboard && dashboardJSON !== null && windowBlurTimestampRef.current !== null) {
			const timeDifference = Date.now() - windowBlurTimestampRef.current;

			windowBlurTimestampRef.current = null;

			if (timeDifference > REFETCH_CANCEL_BUFFER_TIME) {
				if (timeoutId) {
					clearTimeout(timeoutId);
				}

				fetchDashboard();
			}
		}
	}, [timeoutId, windowBlurTimestampRef.current, showDashboard, dashboardJSON]);

	const windowBlurred = useCallback(() => {
		if (timeoutId) {
			windowBlurTimestampRef.current = Date.now();
		}

	}, [timeoutId]);

	useEffect(() => {
		window.addEventListener('focus', windowFocused);
		window.addEventListener('blur', windowBlurred);

		return () => {
			window.removeEventListener('focus', windowFocused);
			window.removeEventListener('blur', windowBlurred);
		};
	}, [windowBlurred, windowFocused]);

	// Handling password submission
	const handlePasswordSubmission = (password: string) => {
		setDashboardChecked(false);
		setPassword(password);
		setShowDashboard(true);
	};

	// Handling passwordless submission
	const handlePasswordlessSubmission = () => {
		setShowDashboard(true);
		trackDashboardOpened('N');
	};

	// Show the dashboard!
	if (showDashboard && dashboardJSON !== null) {
		return <Dashboard
			dashboardJSON={dashboardJSON}
			password={password}
			baseUrl={baseUrl}
		/>;
	}

	// Have we asked the SDK about the dashboard yet?
	if (dashboardChecked) {
		// Is the dashboard in the system?
		if (dashboardValid) {
			// Has the dashboard expired?
			if (expired) {
				// Showing expired screen
				return <Expired />;
			} else if (passwordNeeded) {
				// Showing password required screen
				const hasIncorrectPassword = (passwordNeeded && password !== '');
				return <Password hasIncorrectPassword={hasIncorrectPassword} onSubmit={handlePasswordSubmission}/>;
			} else {
				// Showing passwordless screen
				return <Passwordless onSubmit={handlePasswordlessSubmission}/>;
			}
		} else {
			// Showing error screen
			return <Error />;
		}
	} else {
		// Still checking!
		return <Loader />;
	}
};

const App = ({ env }): ReactElement => (
	<Routes>
		{/* Dashboards */}
		<Route
			element={
				<SharedDashboardContainer
					baseUrl={env.sharedDashboardServiceUrl}
				/>
			}
			path='/dashboard/:sharedDashboardUuid'
		/>
		{/* 404 */}
		<Route element={<Error />} path='*' />
	</Routes>
);

export { App };
