import React, { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import DashboardsEngine from '@brandwatch/dashboards-engine';
import { IDashboard } from '@brandwatch/dashboards-engine/src/decs';
import { bcrToDETransform, IBCRDashboardConfiguration } from '@brandwatch/dashboards-engine/dist/helpers/transform';
import '../App.css';
import { getFormattedMetadata } from '../helpers/metadataFormatter';

interface IDashboardProps {
	dashboardJSON: IDashboardResource;
	password: string;
	baseUrl: string;
}

type TBCRDashboardJSON = IBCRDashboardConfiguration['dashboard'];
type TApiConfiguration = IBCRDashboardConfiguration['apiConfiguration'] & {
	projectId: number;
};

interface IDashboardResource extends TBCRDashboardJSON {
	resourceCreatedAt?: string;
	resourceExpiresAt?: string;
}

const Dashboard = (props: IDashboardProps): ReactElement => {
	const { baseUrl, dashboardJSON, password } = props;
	const { resourceCreatedAt, dashboardTimezone, resourceExpiresAt } = dashboardJSON;
	const { sharedDashboardUuid } = useParams<{ sharedDashboardUuid: string; }>();

	const options = {
		'footer': {
			'show': true
		},
		'header': {
			description: getFormattedMetadata({
				creationDate: resourceCreatedAt || '',
				expirationDate: resourceExpiresAt || '',
				timezone: dashboardTimezone || 'Etc/UTC'
			}),
			'fixed': true,
			'show': true
		}
	};

	// dashboard json from live-dashboard-meta resources are filtered. But DE expects id & projectId.
	// also, zephyrus expects a projectId prop either from the api configuration or per request
	const PLACEHOLDER_ID = -1;

	if (!dashboardJSON.id) {
		dashboardJSON.id = PLACEHOLDER_ID;
	}
	if (!dashboardJSON.projectId) {
		dashboardJSON.projectId = PLACEHOLDER_ID;
	}

	const { hostname, port, protocol } = new URL(baseUrl);
	const apiAuthHeader = `basic ${btoa(`:${password}`)}`;
	const apiConfiguration: TApiConfiguration = {
		apiAuthHeader,
		apiHostname: hostname,
		apiPort: port,
		apiProtocol: protocol,
		pathName: `/dashboard/${sharedDashboardUuid}`,
		projectId: PLACEHOLDER_ID
	};

	const dashboardConfiguration: IBCRDashboardConfiguration = {
		dashboard: dashboardJSON,
		apiConfiguration,
		throttleInterval: 'minute'
	};

	const transformedDashboardJSON: IDashboard = bcrToDETransform(dashboardConfiguration);

	return (
		<DashboardsEngine
			dashboard={transformedDashboardJSON}
			options={options}
		/>
	);
};

export { Dashboard };
